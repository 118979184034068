import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import { Container } from "react-bootstrap";

const Insurance = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="Insurance"
                description="We have successfully completed $40,000,000 worth of digital marketing projects for more than 5,000 clients in more than 120 countries." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <Container className="mb-5">
                        <p className="mt-5">Our Insurance BPS solutions drive Business Process Excellence through a digital-first approach, deep specialty insurance domain expertise and technology point solutions to enhance customer experience, improve business effectiveness and increase efficiency.</p>
                        <p className="mt-3">Our insurance suite of services covers end to end policy lifecycle in Property & Casualty, Life and Annuities and Lender Placed Insurance for Insurers, MGAs, Brokers and Insurance service providers. These services are supported by Customer Experience Centers, Risk and Compliance expertise, Patented platform solutions, Intelligent Automation and Process Analytics.</p>
                        <h6>Some typical examples of what we help our customers achieve:</h6>
                        <h6>Business transformation</h6>
                        <ul className="circle-list">
                            <li className="mt-2">40% cost savings & 15% improvement</li>
                        </ul>

                        <h6>Enhanced customer experience</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Digital-first CX transformation</li>
                        </ul>

                        <h6>Risk management & Quality control</h6>
                        <ul className="circle-list">
                            <li className="mt-2">Technology point solutions like automated QC</li>
                        </ul>

                    </Container>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query InsurancePageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

Insurance.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default Insurance;
